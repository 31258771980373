import { useEffect, useState } from "react";
import { getLogs, getPageInfo } from "../api/API";

export enum PageState {
    LOADING,
    LOGIN,
    OWNER,
    SUB
}

export type Page = {
    setPageState: (state: PageState) => void;
    pageState: PageState;
    pageInfo: any;
    logs: any[];
    purchaseables: any[];
    reloadPageInfo: () => void;
    reloadLogs: () => void;
    reloadPurchaseables: () => void;
    isOwner: boolean;
}

const usePage = (pageUrl: string) => {
    const [pageState, setPageState] = useState(PageState.LOADING);
    const [pageInfo, setPageInfo] = useState({});
    const [logs, setLogs] = useState([]);
    const [purchaseables, setPurchaseables] = useState([]);

    const reloadPageInfo = () => {
        getPageInfo(pageUrl).then((data) => {
            if (data === 404) {
                window.location.href = "/";
                return;
            }

            if (data === 401) {
                setPageState(PageState.LOGIN);
                return;
            }

            setPageInfo(data.page);
            setLogs(data.logs);
            setPurchaseables(data.purchaseables);

            setPageState(data.owner ? PageState.OWNER : PageState.SUB);
        });
    };

    const reloadLogs = async () => {
        const newLogs = await getLogs(pageUrl);

        if (!newLogs) {
            alert("Failed to fetch logs");
            return;
        }

        setLogs(newLogs);
    };

    const reloadPurchaseables = async () => {
        reloadPageInfo();
    };

    useEffect(() => {
        reloadPageInfo();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isOwner = pageState === PageState.OWNER;

    return { pageUrl, setPageState, pageState, pageInfo, logs, purchaseables, reloadPageInfo, reloadLogs, reloadPurchaseables, isOwner };
};

export default usePage;