const url = `${window.location.href.includes("localhost") ? "http://localhost:3001" : "https://loyalty.yiff.ng:3001"}`;

export const session = (pageUrl: string) => {
    return JSON.parse(localStorage.getItem(`session-${pageUrl}`) || "{}");
}

export const saveSession = (pageUrl: string, session: any) => {
    if (session === null || session === undefined) {
        removeSession(pageUrl);
        return;
    }

    localStorage.setItem(`session-${pageUrl}`, JSON.stringify(session));
}

export const removeSession = (pageUrl: string) => {
    localStorage.removeItem(`session-${pageUrl}`);
}

const basicHeaders = (pageUrl: string | undefined) => {
    return {
        "Content-Type": "application/json",
        "Authorization": pageUrl ? `Bearer ${session(pageUrl).session_id ?? ""}` : ""
    };
}

const basicRequest = (pageUrl: string | undefined, method: string, endpoint: string, body: any) =>
    fetch(`${url}/${endpoint}`, {
        method: method,
        headers: basicHeaders(pageUrl),
        body: JSON.stringify(body)
    }).then((res) => {
        if (res.ok) {
            return res.json();
        }

        return res.status;
    });

const basicReturnJson = (pageUrl: string | undefined, method: string, endpoint: string, body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        basicRequest(pageUrl, method, endpoint, body)
            .then((data) => {
                resolve(data);
            }).catch((err) => {
                reject(err);
            });
    });

}

export const getPageInfo = async (pageUrl: string): Promise<any> => {
    const currentSession = session(pageUrl);
    return new Promise((resolve, reject) => {
        fetch(`${url}/page/${pageUrl}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${currentSession.session_id ?? ""}`
            }
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }

                removeSession(pageUrl);
                resolve(res.status);
            })
            .then((data) => {
                resolve({
                    owner: currentSession.owner,
                    page: data.page,
                    logs: data.logs,
                    purchaseables: data.purchaseables
                });
            }).catch((err) => {
                reject(err);
            });
    });
};


export const getLogs = async (pageUrl: string): Promise<any> => basicReturnJson(pageUrl, "GET", `logs/${pageUrl}`, undefined);
export const deleteLog = async (pageUrl: string, logId: number): Promise<any> => basicReturnJson(pageUrl, "DELETE", `logs/${pageUrl}/${logId}`, undefined);
export const authenticate = async (pageUrl: string, password: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        fetch(`${url}/auth/${pageUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "password": password
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }

            resolve(res.status);
        }).then((data) => {
            if (!data) return;

            saveSession(pageUrl, data);
            resolve(data);
        }).catch((err) => {
            reject(err);
        });
    });
};

export const adjustPoints = async (pageUrl: string, points: number, message: string): Promise<any> => basicReturnJson(pageUrl, "POST", `points/adjust/${pageUrl}`, { "points": points, "message": message });
export const resetPoints = async (pageUrl: string, message: string): Promise<any> => basicReturnJson(pageUrl, "POST", `points/reset/${pageUrl}`, { "message": message });
export const createPage = async (title: string, dailyPoints: number, domName: string, domPassword: string, subName: string, subPassword: string): Promise<any> => basicReturnJson(undefined, "POST", "page", {
    "title": title,
    "daily": dailyPoints.toFixed(1),
    "owner_name": domName,
    "owner_password": domPassword,
    "user_name": subName,
    "user_password": subPassword,
    "allow_guests": false
});

export const purchase = async (pageUrl: string, purchaseableId: number, amount: number): Promise<any> => basicReturnJson(pageUrl, "POST", `page/${pageUrl}/purchase`, { "purchaseable_id": purchaseableId, "amount": amount });
export const getPurchaseables = async (pageUrl: string): Promise<any> => basicReturnJson(pageUrl, "GET", `purchaseables/${pageUrl}`, {});
export const getPurchaseable = async (pageUrl: string, purchaseableId: number): Promise<any> => basicReturnJson(pageUrl, "GET", `purchaseables/${pageUrl}/${purchaseableId}`, {});
export const newPurchaseable = async (pageUrl: string, purchaseable: any): Promise<any> => basicReturnJson(pageUrl, "POST", `purchaseables/${pageUrl}`, { "name": purchaseable.name, "description": purchaseable.description, "emoji": purchaseable.emoji, "price": purchaseable.price, "expiry": purchaseable.expiry });
export const savePurchaseable = async (pageUrl: string, purchaseable: any): Promise<any> => basicReturnJson(pageUrl, "POST", `purchaseables/${pageUrl}/${purchaseable.id}`, { "name": purchaseable.name, "description": purchaseable.description, "emoji": purchaseable.emoji, "price": purchaseable.price, "expiry": purchaseable.expiry});
export const deletePurchaseable = async (pageUrl: string, purchaseableId: number): Promise<any> => basicReturnJson(pageUrl, "DELETE", `purchaseables/${pageUrl}/${purchaseableId}`, {});

