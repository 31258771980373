import PageLogin from "./PageLogin";
import PageOwner from "./PageOwner";
import PageSub from "./PageSub";
import usePage, { PageState } from "../hooks/usePage";

const Page = () => {
    const pageUrl = window.location.pathname.substring(1).split("/")[0];
    const page = usePage(pageUrl);

    const element = (() => {
        switch (page.pageState) {
            case PageState.LOADING:
                return <p>Loading...</p>;
            case PageState.LOGIN:
                return <PageLogin pageUrl={pageUrl} onLogin={page.reloadPageInfo} />;
            case PageState.OWNER:
                return <PageOwner page={page} />;
            case PageState.SUB:
                return <PageSub page={page} />;
        }
    })();

    return (
        <div id="page">
            {element}
        </div>
    );
};

export default Page;