import { deleteLog } from '../../api/API';
import { Page } from '../../hooks/usePage';
import './Logs.css';
import { useEffect, useState } from 'react';

type LogsProps = {
    page: Page;
};

const Logs = ({ page }: LogsProps) => {
    const { pageInfo, logs, isOwner: admin, reloadLogs } = page;

    const [pageIndex, setPageIndex] = useState(0);
    const [applicableLogs, setApplicableLogs] = useState<any[]>([]);

    useEffect(() => {
        setPageIndex(0);
    }, [logs]);

    useEffect(() => {
        const newLogs = logs.slice((pageIndex) * 10, (pageIndex + 1) * 10);
        setApplicableLogs(newLogs);
    }, [logs, pageIndex]);

    
    const deleteOnClick = async (logId: number) => {
        const res = await deleteLog(pageInfo.url, logId);
        
        if (!res?.success) {
            alert("Failed to delete log");
            return;
        }

        reloadLogs();
    }

    return (
        <div id="logs">
            <h3>Logs</h3>
            <table>
                <thead>
                    <tr>
                        <th>Time (CEST)</th>
                        <th>Initiator</th>
                        <th>Action</th>
                        <th>Message</th>
                        {admin && <th>Controls</th>}
                    </tr>
                </thead>
                <tbody>
                    {applicableLogs.map((log: any, index) => {
                        const isPositive = log.action.includes("Add") || log.action.includes("+");
                        const isNegative = log.action.includes("Remove") || log.action.includes("-") || log.action.includes("Reset");
                        return (
                            <tr key={index}>
                                <td>{new Date(log.date).toLocaleString()}</td>
                                <td>{log.initiator}</td>
                                <td className={isPositive ? "positive" : (isNegative ? "negative" : undefined)}>{log.action}</td>
                                <td>{log.message}</td>
                                {admin && <td><button className="destructive small" onClick={() => deleteOnClick(log.id)}>Delete</button></td>}
                            </tr>
                        )})}
                </tbody>
            </table>
            <div className="controls">
                <button onClick={() => setPageIndex(pageIndex - 1)} disabled={pageIndex === 0}>Previous</button>
                <span>Page {pageIndex + 1}</span>
                <button onClick={() => setPageIndex(pageIndex + 1)} disabled={logs.length <= (pageIndex + 1) * 10}>Next</button>
            </div>
        </div>
    );
};

export default Logs;