export type Bundle = {
    points: number;
    price: number;
    tag?: string;
    highlight?: boolean;
};

export const pointBundles: Bundle[] = [
    {
        points: 1.2,
        price: 1,
        tag: "Just a bit more for bonuses~"
    },
    {
        points: 7,
        price: 5
    },
    {
        points: 15,
        price: 10
    },
    {
        points: 35,
        price: 25
    },
    {
        points: 72,
        price: 50,
        tag: "If you're feeling cheap..."
    },
    {
        points: 147,
        price: 100
    },
    {
        points: 800,
        price: 500
    },
    {
        points: 1700,
        price: 1000,
        tag: "Best Value <3 Good wallets only.",
        highlight: true
    }
];

export const calculateBonus = (bundle: Bundle): number => {
    const highestPricePer = pointBundles[0].price / pointBundles[0].points;
    if (bundle.price === pointBundles[0].price) return 0;

    return Math.ceil(bundle.points * highestPricePer - bundle.price);
}
    

export const calculateMinMidMax = (points: number): { min: number, mid: number, max: number } => {
    const cheapest = pointBundles[pointBundles.length - 1];
    const mostExpensive = pointBundles[0];

    const min = points / (cheapest.points / cheapest.price);
    const max = points / (mostExpensive.points / mostExpensive.price);

    const average = pointBundles.reduce((acc, bundle) => acc + (bundle.points / bundle.price), 0) / pointBundles.length;
    const mid = points / average;

    return { min, mid, max };
};
