import RadioButton from './RadioButton';
import './RadioButtons.css';

type RadioButtonsProps = {
    values: string[];
    name: string,
    selected: string;
    onChange: (value: string) => void;
    disabled?: boolean;
};

const RadioButtons = ({ values, name, selected, onChange, disabled = false }: RadioButtonsProps) => {
    return (
        <div className={`radio-buttons${disabled ? " disabled" : ""}`}>
            {values.map((value) => (
                <RadioButton 
                    key={value}
                    name={name}
                    value={value}
                    checked={selected === value}
                    onChange={(event) => onChange(event.target.value)}
                    disabled={disabled}
                />
            ))}
        </div>
    );
};

export default RadioButtons;