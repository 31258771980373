export const timespanToHumanReadable = (timespan: number): string => {
    if (timespan < 0) return "0s";

    const fromMs = Math.floor(timespan / 1000);
    const hours = Math.floor(fromMs / 3600);
    const minutes = Math.floor((fromMs % 3600) / 60);
    const seconds = fromMs % 60;

    const showHours = hours > 0;
    const showMinutes = minutes > 0 || showHours;
    return (showHours ? hours + "h, " : "") + (showMinutes ? minutes + "m, " : "") + (seconds + "s");
};