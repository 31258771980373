import './Points.css';
import ManagePoints from "./ManagePoints";
import { useEffect, useState } from 'react';
import { resetPoints } from '../../api/API';
import { Page } from '../../hooks/usePage';

type PointsProps = {
    page: Page;
};

const Points = ({ page }: PointsProps) => {
    const [edit, setEdit] = useState(false);
    const [nextDailyText, setNextDailyText] = useState("00:00:00");

    const updateDailyText = () => {
        const now = new Date();
        const next = new Date();
        next.setHours(24, 0, 0, 0);
        const diff = next.getTime() - now.getTime();

        const hours = Math.floor(diff / 1000 / 60 / 60);
        const minutes = Math.floor((diff / 1000 / 60) % 60);
        const seconds = Math.floor((diff / 1000) % 60);

        setNextDailyText(`${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`);
    };

    useEffect(() => {
        const interval = setInterval(updateDailyText, 1000);
        updateDailyText();
        return () => clearInterval(interval);
    }, []);

    const resetPrompt = () => {
        const message = window.prompt("Are you sure you want to reset the points? You can add a message.");
        if (message !== null) {
            resetPoints(page.pageInfo.url, message).then((data) => {
                if (!data.success) {
                    alert("An error occurred. Please try again.");
                    return;
                }

                page.reloadPageInfo();
            });
        }
    };

    const pageInfo = page.pageInfo;
    const balanceStr = Number.isInteger(pageInfo.balance) ? pageInfo.balance.toString() : pageInfo.balance.toFixed(1);
    const dailyStr = Number.isInteger(pageInfo.daily) ? pageInfo.daily.toString() : pageInfo.daily.toFixed(1);
    const balanceNe1 = pageInfo.balance !== 1;
    const dailyNe1 = pageInfo.daily !== 1;

    const negative = pageInfo.balance < 0;

    return (
        <div id="points">
            <h3>Points</h3>
            <p className={negative ? "negative" : undefined}>Balance: {balanceStr} point{balanceNe1 ? "s" : ""}{negative ? " (fucking idiot~)" : ""}</p>
            <p className="secondary">Daily: +{dailyStr} point{dailyNe1 ? "s" : ""}. Next in: {nextDailyText}</p>
            <button onClick={() => setEdit(!edit)}>Edit</button>
            <button onClick={() => resetPrompt()}>Reset Points :3</button>
            {edit && <ManagePoints page={page} />}
        </div>
    );
};

export default Points;