import { useEffect, useState } from 'react';
import './EditPurchaseable.css';
import Modal from '../../component/modal/Modal';
import { calculateMinMidMax } from '../../PointBundles';
import { maxDigitsIfDecimal } from '../../util/NumberUtil';

type EditPurchaseableProps = {
    purchaseable: any;
    save: (purchaseable: any) => Promise<boolean>;
    del: (purchaseable: any) => Promise<boolean>;
    close: () => void;
};

const EditPurchaseableDialog = ({ purchaseable, save, del, close }: EditPurchaseableProps) => {
    const [name, setName] = useState<string>(purchaseable.name ?? "");
    const [description, setDescription] = useState<string>(purchaseable.description ?? "");
    const [emoji, setEmoji] = useState<string>(purchaseable.emoji ?? "");
    const [price, setPrice] = useState<string>(purchaseable.price ?? 0);
    const [expiry, setExpiry] = useState<number | undefined>(purchaseable.expiry);

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    const toLocalDateTimeString = (timestamp: number) => {
        const date = new Date(timestamp);
        const tzOffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
        const localISOTime = new Date(date.getTime() - tzOffset).toISOString().slice(0, -1);
        return localISOTime.slice(0, 16); // return 'YYYY-MM-DDTHH:MM'
    };

    const promptDelete = async () => {
        if (window.confirm(`Are you sure you want to delete ${purchaseable.name}?`)) {
            if (await del(purchaseable)) {
                close();
            }
        }
    }

    const isCreation = purchaseable.id === undefined;

    const minMidMax = calculateMinMidMax(parseFloat(price));

    return (
        <Modal isOpen>
            <div id="edit-purchaseable">
                <h2>{isCreation ? "Create" : "Edit"} Purchaseable</h2>
                {!isCreation && <span className="editing">Editing: {purchaseable.name} <button className="small destructive" onClick={promptDelete}>Delete</button></span>}
                <form onSubmit={async (e) => {
                    e.preventDefault();
                    if (await save({ id: !isCreation ? purchaseable.id : undefined, name, description, emoji, price, expiry })) {
                        close();
                    }
                }}>
                    <label>
                        Name*:
                        <input type="text" value={name} onChange={e => setName(e.target.value)} required />
                    </label>
                    <label>
                        Description:
                        <textarea value={description} onChange={e => setDescription(e.target.value)} />
                    </label>
                    <label>
                        Emoji:
                        <input type="text" value={emoji} onChange={e => setEmoji(e.target.value)} />
                    </label>
                    <label>
                        Point cost*:
                        <input type="number" value={price} onChange={e => setPrice(e.target.value)} required step={0.5} />
                    </label>
                    <span>Price range is {maxDigitsIfDecimal(minMidMax.min, 1)}€ - {maxDigitsIfDecimal(minMidMax.max, 1)}€ ({maxDigitsIfDecimal(minMidMax.mid, 1)}€ average)</span>
                    <label>
                        <span>Expiry: <button type="button" className="clear secondary small" onClick={() => setExpiry(undefined)}>Clear</button></span>
                        <input type="datetime-local" value={expiry ? toLocalDateTimeString(expiry) : ""} onChange={e => setExpiry(new Date(e.target.value).getTime())} />
                    </label>

                    <div className="actions">
                        <button type="button" className="secondary" onClick={e => { e.stopPropagation(); close() }}>Cancel</button>
                        <button type="submit">Save</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default EditPurchaseableDialog;