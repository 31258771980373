import { useEffect, useState } from 'react';
import './Purchaseable.css';
import { timespanToHumanReadable } from '../../util/TimeUtil';

type PurchaseableProps = {
    admin?: boolean;
    purchaseable: any;
    disabled?: boolean;
    total: number;
    balance: number;
    selections?: any;
    onIncrement: any;
    onDecrement?: any;
    onEdit?: () => void;
};

const Purchaseable = ({ purchaseable, disabled = false, admin = false, balance, total, selections = {}, onIncrement, onDecrement, onEdit = () => { } }: PurchaseableProps) => {
    const selection = admin ? 0 : selections[purchaseable.id] || 0;
    const anySelected = !admin && selection > 0;
    const affordable = admin || total + purchaseable.price <= balance;
    const limited = !!purchaseable.expiry;

    const [expiryString, setExpiryString] = useState<string | undefined>(undefined);
    const updateExpiryString = () => {
        if (!limited) {
            setExpiryString(undefined);
            return;
        }

        setExpiryString(timespanToHumanReadable(purchaseable.expiry - Date.now()));
    }

    useEffect(() => {
        let interval: any = null;

        updateExpiryString();
        interval = setInterval(updateExpiryString, 1000);

        return () => clearInterval(interval);
    }, [purchaseable.expiry]); // eslint-disable-line react-hooks/exhaustive-deps


    const decrementClick = (e: any) => {
        if (disabled) return;
        e.stopPropagation();
        onDecrement();
    }

    return (
        <div className={`purchaseable${anySelected ? " selected" : ""}${limited ? " limited" : ""}${(!affordable || disabled) ? " unaffordable" : ""}`} onClick={() => admin ? onEdit() : !disabled && affordable && onIncrement()}>
            <div className="header">
                {expiryString && <p className="timer">{expiryString} left!!</p>}
                <div className="title">
                    {purchaseable.emoji && <span className="emoji">{purchaseable.emoji}</span>}
                    <span className="name">{purchaseable.name}</span>
                </div>
            </div>
            <span className="price">Price: <span className="positive">{purchaseable.price} Point{purchaseable.price !== 1 ? "s" : ""}</span></span>
            <p className="description">{purchaseable.description}</p>
            {onDecrement && <div className="footer">
                <span className="count">{selection}x ({selection * purchaseable.price} points)</span>
                <button className="decrement secondary" disabled={!selection || disabled} onClick={decrementClick}>-1</button>
            </div>}
        </div>
    )
};

export default Purchaseable;