import './ModalHeader.css';

type ModalHeaderProps = {
    title: string;
    onClose: () => void;
};

const ModalHeader = (props: ModalHeaderProps) => {
    return (
        <div className="modal-header">
            <h3 className="modal-title">{props.title}</h3>
            <button type="button" className="small secondary" onClick={props.onClose}>Close</button>
        </div>
    );
};

export default ModalHeader;