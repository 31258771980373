import './ShoppingCart.css';
import { useState } from "react";
import { Bundle, calculateBonus } from "../../../../PointBundles";
import RadioButtons from "../../../../component/radio/RadioButtons";
import { Page } from "../../../../hooks/usePage";

type ShoppingCartProps = {
    page: Page;
    cart: Bundle[];
    clear: () => void;
    remove: (bundle: Bundle) => void;
    checkout: () => void;
}

const ShoppingCart = ({ cart, clear, remove, checkout }: ShoppingCartProps): JSX.Element => {
    const purchaseMethods = ["Direct", "Savings"];
    const [currentPurchaseMethod, setCurrentPurchaseMethod] = useState<string>(purchaseMethods[0]);

    const total = cart.reduce((acc, cur) => acc + cur.price, 0);
    const totalPoints = cart.reduce((acc, cur) => acc + cur.points, 0);
    const bonus = cart.reduce((acc, cur) => acc + calculateBonus(cur), 0);

    return (
        <div className="shopping-cart">
            <h4>Shopping Cart</h4>
            <div className="cart">
                {cart.length === 0 && <span className="secondary">Your cart is empty.</span>}
                {cart.length > 0 && (
                    <table>
                        <tbody>
                            {cart.map(bundle => (
                                <tr>
                                    <td>{bundle.points} points {calculateBonus(bundle) > 0 && <span className="primary"><b> +{calculateBonus(bundle)}!</b></span>}</td>
                                    <td>{bundle.price}€</td>
                                    <td><button className="destructive small secondary single" onClick={() => remove(bundle)}>-</button></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            {cart.length > 0 && (
                <>
                    <table className="total">
                        <tbody>
                            <tr>
                                <td>Total:</td>
                                <td>{total}€</td>
                            </tr>
                            <tr>
                                <td>Points:</td>
                                <td>{totalPoints} points</td>
                            </tr>
                            <tr>
                                <td>Bonus:</td>
                                <td>{bonus} points</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="pay-with">
                        <span><b>Pay with:</b></span>
                        <RadioButtons name="point-shop-type" values={purchaseMethods} selected={currentPurchaseMethod} onChange={x => setCurrentPurchaseMethod(x)} />
                    </div>
                    <div className="controls">
                        <button onClick={checkout}>Checkout</button>
                        <button className="destructive secondary" onClick={clear}>Clear</button>
                    </div>
                </>
            )}
        </div>
    );
};

export default ShoppingCart;